:root {
  --top_bar_color: black;
  --dark-red:red;
  --red: #dc3545;
  --white: #fff;
  --primary: #6c757d;
  --gray-dark: #343a40;
  --secondary: #6c757d;
  --light: #f8f9fa;
  --dark: #343a40;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --font-xxsm: .2rem;
  --font-xsm: .8rem;
  --font-sm: 1rem;
  --font-md: 1.2rem;
  --font-lg: 1.5rem;
  --font-xlg: 2rem;
  --font-weightMax: 700;
  --font-weightMid: 500;
  --font-weightMin: 400;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: var(--font-family-sans-serif);
  font-size: var(--font-sm);
}


::-webkit-scrollbar {
  width: 10px;
  background-color: var(--light);
}

::-webkit-scrollbar-thumb {
  background-color: var(--dark-red);
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px var(--light);
  background-color: var(--light);
}


body{
  background-color: var(--light);
}

.top-section{
  background-color:  var(--white);
}


.top-header{
  display: flex;
  justify-content: space-between;
  padding: .3rem 12%;
  background-color:  var(--top_bar_color);
  opacity: 80%;
  position: fixed;
  width: 100%;
  z-index: 1;
}
.top-header p{
  color: var(--white);
  font-size: var(--font-xsm);
}
.header-right{
  display: flex;
  justify-content: space-evenly;
  position: relative;
}

.header-right p{
  margin-right: 30px;
}

.header-right .logout-btn{
  border: 0px;
  background-color: transparent;
  color:  var(--white);
  margin-left: 10px;
  cursor: pointer;
  font-size: var(--font-xsm);
}

/* .logout-btn:hover{
  background-color:  var(--bg-color);
  color:  var(--primary-color);
  transition: 0.3s ease-in-out;
  border-radius: 5px;
  padding: 10px;
} */


.menu{
  display: flex;
  justify-content: space-between;
  padding: .5rem 12%;
  color: var(--primary);
  padding-top: 2.5%;
}

.menu button{
  border: 0px;
  color:  var(--primary);
  background-color: transparent;
  padding: 2rem 0;
}

.menu-left .app-name{
  font-size: var(--font-lg);
  font-weight: var(--font-weightMax);
  color: var(--dark-red);
  padding: 2rem 0;
}

.menu-right{
  display: flex;
  flex-direction: column;
}
.cart-btn{
  width: 20px;
  height: 20px;
}


.cart-btn .MuiBadge-badge {
  right: -4px;
  top: 8px;
  background-color: var(--dark-red);
}

.cart-badge{
  color: var(--white);
}
.cart-btn .cart-icon{
  color: var(--primary);
  font-size: 1.3rem;
}

.menu button:hover{
  color: var(--dark-red);
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.menu .active{
  transition: 0.3s ease-in-out;
  border-radius: 5px;
  color:  var(--black);
  text-decoration: underline;
}

.divider-wrapper{
  padding: 0 12%;
}

.divider{
  border: 1px solid var(--primary);
  opacity: .1;
}

.hero-area{
  /* height: 50vh; */
  padding: 0 12%;
  position: relative;
  padding-bottom: 5rem;
  background: url('./images/hero-background-icons.jpg') no-repeat center;
}

.hero-heading{
  padding-top: 3rem;
  font-size: var(--font-xlg);
  font-weight:var(--font-weightMin);
  text-align: center;
  color: var(--primary);
  opacity: .5;
}

.hero-heading > span{
  font-size: var(--font-xlg);
  border-bottom: 3px solid var(--primary);

}

.heading-link:hover{
  color: var(--dark-red);
  border-bottom-color: var(--dark-red);
  transition: 0.2s ease;
  cursor: pointer;
}

.form{
  display: flex;
  flex-direction: column;
  padding-top: 5rem;
  overflow: hidden;
  flex-wrap: wrap;
}

.form .input-label{
  display: block;
  color: var(--dark-red);
  font-size: var(--font-lg);
  padding: .5rem 0;
  
}

.form-btn .input-label{
  visibility: hidden;
}


.form-input input, select{
  width: calc(100vw - 20%);
  padding: .8rem;
  line-height: 1.5;
  border: 1px solid var(--light);
  box-shadow: inset 0 0 1rem 0 var(--light);
  color: var(--primary);
  border-radius: 2px;
}

select{
  cursor: pointer;
}

input:hover, select:hover{
  box-shadow: inset 0 0 0rem 0 var(--light);
}

.form-btn button{
  width: calc(100vw - 20%);
  padding: .8rem;
  background-color: var(--dark-red);
  border: 1px solid var(--dark-red);
  border-radius: 2px;
  color: var(--white);
  cursor: pointer;
  font-weight: var(--font-weightMid);
  
}

.form-btn button:hover{
  box-shadow: 0 0 .8rem 0 var(--primary);
  transition: .2s box-shadow;
  
}

.mid-section{
  padding: 0 12%;
  color: var(--black);
}

.mid-section-header{
  padding-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mid-section-header p{
  font-size: var(--font-lg);
  color: var(--black);
  padding: .5rem 0;
}

.mid-section-header select{
  width: calc(100vw - 85vw);
  padding: .5rem;
  border: 1px solid var(--primary);
  box-shadow: inset 0 0 1rem 0 var(--light);
  color: var(--primary);
  border-radius: 2px;
}

.items-div{
  display: flex;
  flex-direction: column;
}



@media only screen and (min-width:768px){

  .menu, .menu-right, .mid-section-header{
      flex-direction: row;
  }

  .menu-right button{
    padding-left: 2rem;
  }
  
  .toggle-btn{
    display: none;
  }

  .form{
    flex-direction: row;
    padding: 8.84%;
    padding-bottom: 0;
  }

  .form-input, .form-picker{
    margin-right: .6rem;
  }

  .form-input input, select{
    width: 23.4rem;
  }

  .form-btn button{
    width: 11.4rem;
    
  }

  .items-div{
    flex-direction: row;
    flex-wrap: wrap;
  }

}
.message-us{
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 10vh;
  right: 5vw;
  z-index: 1;
  background-color: var(--dark-red);
  cursor: pointer;
  border-radius: 50%;
  background-color: var(--dark-red);
  border: 1px solid --dark-red;
}

.message-us p{
  color: var(--white);
  font-size: 1.5rem;
  text-align: center;
  margin-top: 18%;
}

.message-us:hover{
  background-color: var(--top_bar_color);
}

.chatbot{
  position: fixed;
  bottom: 6%;
  right: 10%;
  z-index: 99999999;
}


.notify-btn{
  width: 20px;
  height: 20px;
}

.notify-btn .notify-icon{
  color: var(--primary);
  margin-right: 30px;
  font-size: 1.5rem;
}

.notify-btn .MuiBadge-badge {
  right: 60%;
  top: 40%;
  background-color: var(--dark-red);
}

.notify-badge{
  color: var(--white);
}

.notify-modal{
  position: absolute;
  top: 100%;
  right: 30%;
  width: 40vw;
  height: 40vh;
  background-color: var(--light);
  padding: 5px;
  border: 2px solid var(--dark);
  border-radius: 5px;
  overflow: auto;
}

.notify-modal .notify-div{
  width: 100%;
  height: 15%;
  margin-bottom: 5px;
  background-color: var(--dark);
  color: var(--white);
  padding: 5px;
  border-radius: 5px;
}

.notify-div p{
  font-size: var(--font-sm);
}
